// extracted by mini-css-extract-plugin
export var buttonContainer = "visual-language-module--buttonContainer--1QMGG";
export var fixedSpace1 = "visual-language-module--fixedSpace1--y99l8";
export var fixedSpace10 = "visual-language-module--fixedSpace10--UqViJ";
export var fixedSpace11 = "visual-language-module--fixedSpace11--upMR-";
export var fixedSpace12 = "visual-language-module--fixedSpace12--jKMtI";
export var fixedSpace13 = "visual-language-module--fixedSpace13--6xt-9";
export var fixedSpace14 = "visual-language-module--fixedSpace14--h+yiQ";
export var fixedSpace15 = "visual-language-module--fixedSpace15--M-kEy";
export var fixedSpace16 = "visual-language-module--fixedSpace16--nEzvi";
export var fixedSpace2 = "visual-language-module--fixedSpace2---Yeb+";
export var fixedSpace3 = "visual-language-module--fixedSpace3--k0dBv";
export var fixedSpace4 = "visual-language-module--fixedSpace4--aDAAA";
export var fixedSpace5 = "visual-language-module--fixedSpace5--1+X+l";
export var fixedSpace6 = "visual-language-module--fixedSpace6--7UOaK";
export var fixedSpace7 = "visual-language-module--fixedSpace7--6pB9I";
export var fixedSpace8 = "visual-language-module--fixedSpace8--JvTvR";
export var fixedSpace9 = "visual-language-module--fixedSpace9--xVhAB";
export var highShadow = "visual-language-module--highShadow--I9usi";
export var l = "visual-language-module--l--3p7VH";
export var lowShadow = "visual-language-module--lowShadow--PI-lN";
export var m = "visual-language-module--m--CiYOh";
export var mediumShadow = "visual-language-module--mediumShadow--wj0H-";
export var neutral100 = "visual-language-module--neutral100--0Cyxt";
export var neutral200 = "visual-language-module--neutral200--RclZ0";
export var neutral300 = "visual-language-module--neutral300--45ZE-";
export var neutral400 = "visual-language-module--neutral400--lVVGz";
export var neutral50 = "visual-language-module--neutral50--syHOx";
export var neutral500 = "visual-language-module--neutral500--JlQ-P";
export var neutral600 = "visual-language-module--neutral600--vkPqZ";
export var neutral700 = "visual-language-module--neutral700--cz2j3";
export var neutral800 = "visual-language-module--neutral800---JC49";
export var neutral900 = "visual-language-module--neutral900--NfEql";
export var primary100 = "visual-language-module--primary100---AM1M";
export var primary200 = "visual-language-module--primary200--nA+9L";
export var primary300 = "visual-language-module--primary300--UQwBy";
export var primary400 = "visual-language-module--primary400--EDUWf";
export var primary50 = "visual-language-module--primary50--eBGR8";
export var primary500 = "visual-language-module--primary500--wev2Y";
export var primary600 = "visual-language-module--primary600--B8Dgs";
export var primary700 = "visual-language-module--primary700--j2tN1";
export var primary800 = "visual-language-module--primary800--pChWD";
export var primary900 = "visual-language-module--primary900--ooUO7";
export var s = "visual-language-module--s--3tA2E";
export var secondary100 = "visual-language-module--secondary100--xnw4k";
export var secondary200 = "visual-language-module--secondary200--ORJau";
export var secondary300 = "visual-language-module--secondary300--EV2Bi";
export var secondary400 = "visual-language-module--secondary400--0lIEk";
export var secondary50 = "visual-language-module--secondary50--gx8AW";
export var secondary500 = "visual-language-module--secondary500--K4mI2";
export var secondary600 = "visual-language-module--secondary600--Tnjxp";
export var secondary700 = "visual-language-module--secondary700--QYf+C";
export var secondary800 = "visual-language-module--secondary800--isUHT";
export var secondary900 = "visual-language-module--secondary900--N+B4R";
export var space1 = "visual-language-module--space1--qIxZW";
export var space10 = "visual-language-module--space10---qZS0";
export var space11 = "visual-language-module--space11--X2UmY";
export var space12 = "visual-language-module--space12--HIW4b";
export var space13 = "visual-language-module--space13--acQdG";
export var space14 = "visual-language-module--space14--jqndT";
export var space15 = "visual-language-module--space15---TvQP";
export var space16 = "visual-language-module--space16--E6nK7";
export var space2 = "visual-language-module--space2--uIvFu";
export var space3 = "visual-language-module--space3--ExYPW";
export var space4 = "visual-language-module--space4--g+-X4";
export var space5 = "visual-language-module--space5--6JBAi";
export var space6 = "visual-language-module--space6--Wjs7R";
export var space7 = "visual-language-module--space7--C4hvB";
export var space8 = "visual-language-module--space8--lud1s";
export var space9 = "visual-language-module--space9--VheHK";
export var spacePanel = "visual-language-module--spacePanel--yiWXI";
export var title = "visual-language-module--title--itSy3";
export var xl = "visual-language-module--xl--nVNVU";
export var xs = "visual-language-module--xs--WuSLl";
export var xxl = "visual-language-module--xxl--sTP+C";
export var xxs = "visual-language-module--xxs--ybdig";